<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.contrato.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.contrato.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Contrato', 'Inserir')"
      :sem-filtro="!buscarPermissao('Contrato', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('Contrato', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      ref="tabela"
      :key="keyAtualiza"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      filtros-com-colunas
      :mostrar-seletor="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @filtrarGlobal="listarRegistros"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:valorTotal="{ slotProps }">
        <div class="alinhar-direita">
          {{ formatacaoMoeda(slotProps.data.valorTotal) }}
        </div>
      </template>

      <template v-slot:valorMensal="{ slotProps }">
        <div class="alinhar-direita">
          {{ formatacaoMoeda(slotProps.data.valorMensal) }}
        </div>
      </template>
      <template v-slot:acoes="{ slotProps }">
        <div class="d-flex">
          <dropdown-padrao
            v-if="mostraDropDown"
            text
            color="secondary"
          >
            <template #botao>
              <v-icon> $dotsVertical </v-icon>
            </template>
            <v-list-item-group style="overflow-y: auto">
              <v-list-item
                v-if="buscarPermissao('Contrato', 'Editar')"
                class="d-flex min-height-drop-item"
                :disabled="!podeEditar(slotProps.data.situacao)"
                @click="abrirEditar(slotProps.data)"
              >
                {{ $t('geral.botoes.editar') }}
              </v-list-item>
              <v-divider />
              <v-list-item
                v-if="buscarPermissao('Contrato', 'EditarComoCliente')"
                class="d-flex min-height-drop-item"
                :disabled="slotProps.data.situacao !== 'AguardandoAprovacao'"
                @click="abrirEditarCliente(slotProps.data)"
              >
                Editar como Cliente
              </v-list-item>
              <v-divider />
              <v-list-item
                v-if="buscarPermissao('Contrato', 'Visualizar')"
                class="d-flex min-height-drop-item"
                @click="abrirVisualizacao(slotProps.data)"
              >
                {{ $t('geral.botoes.visualizar') }}
              </v-list-item>
              <v-divider />
              <dropdown-padrao
                submenu
                color="secondary"
              >
                <template #botao>
                  <v-list-item-group
                    style="font-weight: 400; padding: 0 !important"
                    class="d-flex min-height-drop-item v-list-item v-list-item--link theme--light"
                  >
                    {{ $t('geral.botoes.alterar_status') }}
                  </v-list-item-group>
                </template>
                <v-list-item-group style="overflow-y: auto">
                  <v-list-item
                    v-if="buscarPermissao('Contrato', 'Liberar')"
                    class="d-flex"
                    :disabled="slotProps.data.situacao !== 'EmNegociacao'"
                    @click="liberarContrato(slotProps.data)"
                  >
                    {{ $t('geral.botoes.liberar') }}
                  </v-list-item>
                  <v-divider />
                  <dropdown-padrao
                    submenu
                    color="secondary"
                  >
                    <template #botao>
                      <v-list-item-group
                        style="font-weight: 400; padding: 0 !important"
                        class="d-flex min-height-drop-item v-list-item v-list-item--link theme--light"
                      >
                        {{ $t('geral.botoes.enviar_cliente') }}
                      </v-list-item-group>
                    </template>
                    <v-list-item-group style="overflow-y: auto">
                      <v-list-item
                        v-if="buscarPermissao('Contrato', 'EnviarAoCliente')"
                        class="d-flex"
                        :disabled="
                          slotProps.data.situacao !==
                            'AguardandoEnvioParaCliente'
                        "
                        @click="enviarCliente(slotProps.data)"
                      >
                        {{ $t('geral.botoes.enviar_email') }}
                      </v-list-item>
                      <v-divider />
                      <v-list-item
                        v-if="buscarPermissao('Contrato', 'EnviarAoCliente')"
                        class="d-flex"
                        :disabled="
                          [
                            'EmNegociacao',
                            'AguardandoEnvioParaCliente',
                          ].includes(slotProps.data.situacao)
                        "
                        @click="reenviarEmailCliente(slotProps.data.id)"
                      >
                        {{ $t('geral.botoes.reenviar_email') }}
                      </v-list-item>
                      <v-divider />
                      <v-list-item
                        v-if="buscarPermissao('Contrato', 'EnviarAoCliente')"
                        class="d-flex"
                        :disabled="
                          slotProps.data.situacao !==
                            'AguardandoEnvioParaCliente'
                        "
                        @click="marcarComoEnviado(slotProps.data)"
                      >
                        {{ $t('geral.botoes.marcar_como_enviado') }}
                      </v-list-item>
                    </v-list-item-group>
                  </dropdown-padrao>
                  <v-divider />
                  <v-list-item
                    v-if="buscarPermissao('Contrato', 'PreAssinar')"
                    class="d-flex"
                    :disabled="
                      slotProps.data.situacao !== 'AguardandoAprovacao'
                    "
                    @click="preAssinarContrato(slotProps.data.id)"
                  >
                    {{ $t('geral.botoes.pre_assinado') }}
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    v-if="buscarPermissao('Contrato', 'Assinar')"
                    class="d-flex"
                    :disabled="
                      slotProps.data.situacao !== 'AguardandoAprovacao' &&
                        slotProps.data.situacao !== 'PreAssinado'
                    "
                    @click="abreModalDataAssinatura(slotProps.data.id)"
                  >
                    {{ $t('geral.botoes.assinar') }}
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    v-if="buscarPermissao('Contrato', 'Reprovar')"
                    class="d-flex"
                    :disabled="
                      slotProps.data.situacao !== 'AguardandoAprovacao'
                    "
                    @click="abreModalDataReprovacao(slotProps.data.id)"
                  >
                    {{ $t('geral.botoes.reprovar') }}
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    v-if="buscarPermissao('Contrato', 'Cancelar')"
                    class="d-flex"
                    :disabled="slotProps.data.situacao !== 'Assinado'"
                    @click="abreModalDataCancelamento(slotProps.data.id)"
                  >
                    {{ $t('geral.botoes.cancelar') }}
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    v-if="buscarPermissao('Contrato', 'Encerrar')"
                    class="d-flex"
                    :disabled="slotProps.data.situacao !== 'Assinado'"
                    @click="abreModalDataEncerramento(slotProps.data.id)"
                  >
                    {{ $t('geral.botoes.encerrar') }}
                  </v-list-item>
                </v-list-item-group>
              </dropdown-padrao>
              <v-divider />
              <v-list-item
                v-if="buscarPermissao('Contrato', 'AjustarValores')"
                class="d-flex min-height-drop-item"
                @click="abreModalAjustarValor(slotProps.data.id)"
              >
                {{ $t('geral.botoes.ajustar_atualizar_valores') }}
              </v-list-item>
              <v-divider />
              <v-list-item
                v-if="buscarPermissao('Contrato', 'Renovar')"
                class="d-flex min-height-drop-item"
                @click="duplicarContrato(slotProps.data.id)"
              >
                {{ $t('geral.botoes.renovar_contrato') }}
              </v-list-item>
              <v-divider />
              <v-list-item
                v-if="
                  verificarContrato(slotProps.data) &&
                    buscarPermissao('Contrato', 'VisualizarRevisao')
                "
                class="d-flex min-height-drop-item"
                @click="abrirVisualizarRevisoes(slotProps.data)"
              >
                {{ $t('geral.botoes.visualizar_revisoes') }}
              </v-list-item>
              <v-divider />
              <v-list-item
                v-if="buscarPermissao('Contrato', 'Imprimir')"
                class="d-flex min-height-drop-item"
                @click="gerarRelatorio(slotProps.data)"
              >
                {{ $t('geral.botoes.imprimir') }}
              </v-list-item>
              <v-divider />
              <v-list-item
                v-if="buscarPermissao('Contrato', 'CriarAditivo')"
                class="d-flex min-height-drop-item"
                @click="criarNovoAditivoConfirmacao(slotProps.data)"
              >
                {{ $t('modulos.contrato.criar_novo_aditivo') }}
              </v-list-item>
            </v-list-item-group>
          </dropdown-padrao>
          <icone-padrao
            :icone="buscaStatusIcon(slotProps.data.situacao)"
            :tooltip="buscaStatusMensagemTooltip(slotProps.data.situacao)"
          />
        </div>
      </template>
      <template v-slot:participante="{ slotProps }">
        {{ slotProps.data.participante.nome }}
      </template>
      <template v-slot:codigo="{ slotProps }">
        <div
          v-if="slotProps.data.flagAditivo"
          class="d-flex"
          style="align-items: center"
        >
          <span>
            {{ slotProps.data.codigo }}
          </span>
          <p class="codigo-aditivo ml-2 mb-0">
            {{ $t('modulos.contrato.aditivo_parenteses') }}
          </p>
          <span v-if="slotProps.data.quantidadeRevisoes">
            ({{
              `${slotProps.data.quantidadeRevisoes} ${
                slotProps.data.quantidadeRevisoes == 1 ? 'Revisão' : 'Revisões'
              }`
            }})
          </span>
        </div>
        <div v-if="!slotProps.data.flagAditivo">
          <span>
            {{ slotProps.data.codigo }}
          </span>
          <span v-if="slotProps.data.quantidadeRevisoes">
            ({{
              `${slotProps.data.quantidadeRevisoes} ${
                slotProps.data.quantidadeRevisoes == 1 ? 'Revisão' : 'Revisões'
              }`
            }})
          </span>
        </div>
      </template>
    </tabela-padrao-prime-vue>
    <data-assinatura-modal
      ref="modal-data-assinatura"
      :id-assinatura="idContrato"
      @assinar-contrato="assinarContrato"
    />
    <data-reprovacao-modal
      ref="modal-data-reprovacao"
      :id-assinatura="idContrato"
      @alterar-status-contrato="alterarStatusContrato"
    />
    <data-aprovacao-modal
      ref="modal-data-aprovacao"
      :id-assinatura="idContrato"
      @alterar-status-contrato="aprovarContrato"
    />
    <data-cancelamento-modal
      ref="modal-data-cancelamento"
      :id-assinatura="idContrato"
      @alterar-status-contrato="cancelarContrato"
    />
    <data-encerramento-modal
      ref="modal-data-encerramento"
      :id-assinatura="idContrato"
      @alterar-status-contrato="encerrarContrato"
    />
    <ajustar-valor-modal
      ref="modal-ajustar-valor"
      @atualizar-listagem-contrato="listarRegistros"
    />
    <revisao-modal
      ref="modal-revisao"
      @salvar-novo="buscarIdOrigen"
    />
    <justificativa-nova-revisao
      ref="justificativa-nova-revisao"
      @salvar-novo="comentarioRevisao"
    />
    <revisao-lista-modal
      ref="modal-lista-revisao"
      @salvar-novo="buscarIdOrigen"
    />
    <reenvio-email-modal
      ref="modal-reenviar-email"
      :tipo-email="'contrato'"
    />

    <modal-visualizacao-relatorio ref="modal-pdf" />
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import ContratoService from '@common/services/cadastros/ContratoService';
import { ModalVisualizacaoRelatorio } from '@components/misc';
import helpers from '@common/utils/helpers';
import Filtro from './filtro';
import DataAssinaturaModal from './components/modais/DataAssinaturaModal.vue';
import DataReprovacaoModal from './components/modais/DataReprovacaoModal.vue';
import DataAprovacaoModal from './components/modais/DataAprovacaoModal.vue';
import DataCancelamentoModal from './components/modais/DataCancelamentoModal.vue';
import DataEncerramentoModal from './components/modais/DataEncerramentoModal.vue';
import AjustarValorModal from './components/modais/AjustarValorModal.vue';
import { enumContratoIcons } from '@enums/iconsContrato';
import RevisaoModal from './components/modais/RevisaoModal.vue';
import ComentarioService from '@common/services/cadastros/ComentarioService';
import RevisaoListaModal from './components/modais/RevisaoListaModal.vue';
import ReenvioEmailModal from '@components/fragments/Modals/ReenvioEmailModal';
import JustificativaNovaRevisao from './components/modais/JustificativaNovaRevisao.vue';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
    DataAssinaturaModal,
    DataReprovacaoModal,
    DataCancelamentoModal,
    DataEncerramentoModal,
    DataAprovacaoModal,
    AjustarValorModal,
    RevisaoModal,
    RevisaoListaModal,
    ReenvioEmailModal,
    JustificativaNovaRevisao,
    ModalVisualizacaoRelatorio,
  },
  data() {
    return {
      keyAtualiza: 1,
      mostraDropDown: true,
      idContrato: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'participante',
            text: this.$t('modulos.contrato.tabela.cliente'),
            sortable: true,
          },
          {
            value: 'codigo',
            text: this.$t('modulos.contrato.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'dataInicio',
            text: this.$t('modulos.contrato.tabela.data_inicio'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'tipoContrato',
            text: this.$t('modulos.contrato.tabela.tipo'),
            sortable: true,
            formatter: (v) =>
              helpers.TipoContratoEnum.find((el) => el.value == v)?.text,
          },
          {
            value: 'dataEnvio',
            text: this.$t('modulos.contrato.tabela.data_envio'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'valorTotal',
            text: this.$t('modulos.contrato.tabela.valor_final'),
            sortable: true,
          },
          {
            value: 'valorMensal',
            text: this.$t('modulos.contrato.tabela.valor_mensal'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Contrato', 'Listar');
    const filtros = helpers.buscarStateFiltros(this);
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.contrato.titulos.listagem')
    );
    this.listarRegistros();
  },
  methods: {
    podeEditar(situacao){
      return !["Assinado","Reprovado", "Encerrado", "Cancelado"].includes(situacao);
    },
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    criarNovoAditivoConfirmacao(item) {
      if (!item.flagAditivo) {
        this.confirmarCriarAditivoContrato(item.codigo).then(() => {
          this.criarNovoAditivo(item.id);
        });
      } else {
        this.confirmarCriarAditivoContratoJaAditivo(
          item.codigo,
          item.sequenciaContratoAditivo
        ).then(() => {
          this.criarNovoAditivo(item.id);
        });
      }
    },
    criarNovoAditivo(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ContratoService.criarNovoAditivoContrato(id)
        .then(() => {
          this.listarRegistros();
        })
        .catch(() => {
          this.toastErro(this.$t(`geral.erros.erroGenerico`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    reenviarEmailCliente(id) {
      this.dropDownOculta();
      this.$refs['modal-reenviar-email'].abrirModal(id);
    },
    buscaStatusIcon(status) {
      let icon = null;
      enumContratoIcons.forEach((item) => {
        if (item.value === status) icon = item.icon;
      });
      return this.$vuetify.icons.values[icon];
    },
    buscaStatusMensagemTooltip(status) {
      let message = null;
      enumContratoIcons.forEach((item) => {
        if (item.value === status) message = item.message;
      });
      return message;
    },
    liberarContrato(item) {
      this.dropDownOculta();
      let params = {
        id: item.id,
        status: 8,
        dataStatus: new Date(),
        justificativa: '',
        enviaEmail: false,
      };
      this.confirmarLiberarContrato().then(() => {
        this.alterarStatusContrato(params);
      });
    },
    preAssinarContrato(id) {
      this.$router.push({
        name: 'contrato-assinar',
        params: { id },
      });
    },
    assinarContrato(item) {
      let params = {
        id: item.id,
        status: 4,
        dataStatus: item.dataAssinatura,
        justificativa: item.justificativa,
        enviaEmail: false,
      };
      this.alterarStatusContrato(params);
    },
    aprovarContrato(item) {
      let params = {
        id: item.id,
        status: 4,
        dataStatus: item.dataAprovacao,
        justificativa: item.justificativa,
        enviaEmail: false,
      };
      this.alterarStatusContrato(params);
    },
    cancelarContrato(item) {
      let params = {
        id: item.id,
        status: 6,
        dataStatus: item.dataCancelamento,
        justificativa: item.justificativa,
        enviaEmail: false,
      };
      this.alterarStatusContrato(params);
    },
    encerrarContrato(item) {
      let params = {
        id: item.id,
        status: 7,
        dataStatus: item.dataEncerramento,
        justificativa: item.justificativa,
        enviaEmail: false,
      };
      this.alterarStatusContrato(params);
    },
    alterarStatusContrato(params) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ContratoService.alteraStatus(params)
        .then(() => {
          this.listarRegistros(this.tabela.paginaAtual, this.tabela.porPagina);
        })
        .catch((err) => {
          if (
            err.response.data.errors[0] == 'contratoComInstrumentoJaExistente'
          ) {
            this.toastAlerta(
              this.$t(`geral.erros.contrato_instrumento_existente`)
            );
            return;
          }
          this.toastErro(this.$t(`geral.erros.erroGenerico`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.keyAtualiza++;
        });
    },
    dropDownOculta() {
      this.mostraDropDown = false;
      setTimeout(() => {
        this.mostraDropDown = true;
      }, 300);
    },
    abreModalDataAssinatura(id) {
      this.dropDownOculta();
      this.idContrato = id;
      this.$refs['modal-data-assinatura'].abrirModal();
    },
    abreModalDataReprovacao(id) {
      this.dropDownOculta();
      this.idContrato = id;
      this.$refs['modal-data-reprovacao'].abrirModal();
    },
    abreModalDataAprovacao(id) {
      this.dropDownOculta();
      this.idContrato = id;
      this.$refs['modal-data-aprovacao'].abrirModal();
    },
    abreModalDataCancelamento(id) {
      this.dropDownOculta();
      this.idContrato = id;
      this.$refs['modal-data-cancelamento'].abrirModal();
    },
    abreModalDataEncerramento(id) {
      this.dropDownOculta();
      this.idContrato = id;
      this.$refs['modal-data-encerramento'].abrirModal();
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    abreModalAjustarValor(id) {
      this.mostraDropDown = false;
      setTimeout(() => {
        this.mostraDropDown = true;
      }, 300);
      this.$refs['modal-ajustar-valor'].abrirModal(id);
    },
    listarRegistros: async function (
      paginaAtual = this.tabela.paginaAtual, 
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.paginaAtual = paginaAtual; 
      this.tabela.porPagina = porPagina;
      helpers.salvarStateFiltros(this, {
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });

      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      ContratoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .catch(() => {
          this.toastErro(this.$t(`geral.erros.erroGenerico`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.map((item) => {
          if (item.id) this.excluir(item.id);
        });
      });
    },
    excluir(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ContratoService.excluir(id)
        .then(() => {
          this.listarRegistros(this.tabela.paginaAtual, this.tabela.porPagina);
          this.toastSucesso(this.$t(`modulos.contrato.exclusao_sucesso`));
          this.tabela.selecionados = [];
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.tabela.selecionados = [];
        });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'contrato-novo' });
    },
    abrirVisualizacao: function (item) {
      this.$router.push({
        name: 'contrato-editar',
        params: { id: item.id },
        query: { visualizacao: true },
      });
    },
    abrirEditar: function (item) {
      // if (item.situacao == 'AguardandoAprovacao') {
      //   this.abrirRevisaoContrato(item);
      // } else {
      this.$router.push({
        name: 'contrato-editar',
        params: { id: item.id },
      });
      // }
    },
    buscarIdOrigen: function (id, tipoJustificativa) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ContratoService.buscar(id.id ?? id)
        .then((res) => {
          let resultado = res.data;
          this.abrirComentarioContrato(resultado, tipoJustificativa);
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.contrato.erros.contrato_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abrirRevisaoContrato: function (item) {
      this.$refs['modal-revisao'].abrirModal(item);
    },
    revisaoContrato: function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ContratoService.revisaoContrato(id)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.contrato.revisao_sucesso`));
          this.listarRegistros();
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abrirComentarioContrato: function (form, tipoJustificativa) {
      this.$refs['justificativa-nova-revisao'].abrirModal(
        form,
        tipoJustificativa
      );
      this.tabela.selecionados = [];
    },
    comentarioRevisao: function (form) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ComentarioService.salvar(form)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.contrato.justificativa_sucesso`));
          this.revisaoContrato(form.contratoId);
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abrirEditarCliente: function (item) {
      this.$router.push({
        name: 'contrato-editar-cliente',
        params: { id: item.id },
      });
    },
    redefinirContrato(item) {
      this.confirmarRedefinirContrato().then(() => {
        this.alterarStatusContrato(item);
      });
    },
    // reprovarContrato(item) {
    //   this.confirmarReprovarContrato().then(() => {
    //     this.alterarStatusContrato(item.id, 5);
    //   });
    // },
    enviarCliente(item) {
      let params = {};
      if (item.situacao == 'Assinado') {
        params = {
          id: item.id,
          status: 4,
          dataStatus: new Date(),
          justificativa: '',
          enviaEmail: true,
        };
      } else {
        params = {
          id: item.id,
          status: 2,
          dataStatus: new Date(),
          justificativa: '',
          enviaEmail: true,
        };
      }
      this.alterarStatusContrato(params);
    },
    marcarComoEnviado(item) {
      this.dropDownOculta();
      let mensagem;
      if (
        !this.tabela.selecionados.length ||
        this.tabela.selecionados.length == 'EmNegociacao'
      ) {
        mensagem = this.$t(
          `geral.alertas.marcar_como_enviado.mensagem_selecionado`
        );
      } else
        mensagem = this.$t(
          `geral.alertas.marcar_como_enviado.mensagem_selecionados`
        );

      this.confirmarMarcarComoEnviado(mensagem).then(() => {
        let params = {};
        if (item.situacao == 'Assinado') {
          params = {
            id: item.id,
            status: 4,
            dataStatus: new Date(),
            justificativa: '',
            enviaEmail: false,
          };
        } else {
          params = {
            id: item.id,
            status: 2,
            dataStatus: new Date(),
            justificativa: '',
            enviaEmail: false,
          };
        }
        this.alterarStatusContrato(params);
      });
    },
    duplicarContrato(id) {
      let participanteId = localStorage.getItem('participanteLogadoId');
      let params = {
        id: id,
        participanteId: participanteId,
        opcoesContrato: 1,
      };
      this.confirmarDuplicarContrato().then(() => {
        ContratoService.duplicarContrato(id, params)
          .then(() => {
            this.toastSucesso(this.$t(`modulos.contrato.duplicar_renovar`));
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
          })
          .catch(() => {
            this.toastErro(this.$t(`geral.erros.erroGenerico`));
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    verificarContrato: function (item) {
      if(item) return true;
      // return item.codigo?.includes('R') ? false : true;
    },
    abrirVisualizarRevisoes: function (item) {
      this.$refs['modal-lista-revisao'].abrirModal(item);
    },
    gerarRelatorio: function (item) {
      this.$refs['modal-pdf'].abrirModal('contrato', [
        { simbolo: 'id', valor: item.id },
        { simbolo: 'tags', valor: true },
      ]);
    },
    formatacaoMoeda(valor) {
      if (valor) return helpers.formatarMoeda(valor);
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
<style>
.min-height-drop-item {
  min-height: 35px !important;
}
.codigo-aditivo {
  color: orange;
}
.alinhar-direita {
  display: flex;
  justify-content: right;
}
</style>

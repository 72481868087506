<template>
  <div class="row">
    <input-text
      v-model="filtro.nomeCliente"
      class="col-12 col-md-6"
      :label="$t('modulos.contrato.filtro.cliente')"
      em-filtro
    />
    <input-text
      v-model="filtro.codigo"
      class="col-12 col-md-6"
      :label="$t('modulos.contrato.filtro.codigo')"
      em-filtro
    />
    <input-date
      :key="atualizarKey"
      v-model="filtro.dataInicio"
      class="col-12 col-md-6"
      :label="$t('modulos.contrato.filtro.data_inicio')"
      em-filtro
    />
    <input-select
      v-model="filtro.tipoContrato"
      class="col-12 col-md-6"
      :label="$t('modulos.contrato.filtro.tipo')"
      :options="opcoes.tipoContrato"
      em-filtro
      retornar-objeto
    />
    <input-select
      v-model="filtro.status"
      class="col-12 col-md-12"
      :label="$t('modulos.contrato.filtro.situacao')"
      :options="opcoes.situacaoContrato"
      em-filtro
      retornar-objeto
    />
  </div>
</template>
<script>
import EnumeradorService from "@common/services/cadastros/EnumeradorService";
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      atualizarKey: 0,
      itemValue: 'seq',
      opcoes: {
        situacaoContrato: [],
        tipoContrato: [],
      },
    };
  },
  watch:{
    'filtro.dataInicio'(v){
      this.filtro.dataInicio = v?.substring(0,10)
      this.atualizarKey++
    }
  },
  mounted() {
    this.buscarSituacaoContrato();
    this.buscarTipoContrato();
  },
  methods: {
    buscarSituacaoContrato: function(){
      EnumeradorService.buscar('EnumSituacaoContrato').then((res) => {
        this.opcoes.situacaoContrato = res;
      });
    },
    buscarTipoContrato: function(){
      EnumeradorService.buscar('EnumTipoContrato').then((res) => {
        this.opcoes.tipoContrato = res;
      });

    }
  }
};
</script>

<template>
  <modal-padrao
    ref="modal"
    max-width="50%"
    :ok-desabilitado="!valido"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo="titulo"
    @ok="confirmacaoSalvar"
  >
    <v-form
      ref="form"
      class="row mt-2"
    >
      <p class="px-3">
        {{ justificativa }}
      </p>
      <input-textarea
        v-model="form.mensagem"
        class="col-12 col-md-12"
        :label="$t('modulos.contrato.tabela.modal_add_revisao.justificativa')"
        obrigatorio
      />
    </v-form>
  </modal-padrao>
</template>
<script>
export default {
  props: {
    revisao: String,
  },
  data() {
    return {
      valido: null,
      form: {},
      titulo: '',
      justificativa: '',
    };
  },
  computed: {
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      if (this.form.contratoItem.length == 0)
        return this.$t('modulos.contrato.validacoes.escopo_vazio');
      return this.$t('modulos.contrato.validacoes.formulario_invalido');
    },
  },
  watch: {
    form: {
      handler() {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
      },
      deep: true,
    },
  },
  methods: {
    iniciarForm: function () {
      this.form = {
        contratoId: null,
        tipoComentario: 1,
        mensagem: null,
        flagPrivado: true,
        flagNotificar: true,
        status: false,
        comentarioProprietarioId: null,
      };
    },
    abrirModal: function (contrato, tipoJustificativa) {
      this.iniciarForm();
      this.definirTitulo(tipoJustificativa);
      this.form.contratoId = contrato.id;

      this.$refs['modal'].abrirModal();
      if (this.$refs.form) this.valido = this.$refs.form.resetValidation();
    },
    salvar: function () {
      if (!this.$refs.form.validate()) return;

      //this.form.comentarioProprietarioId = localStorage.getItem("usuarioId")

      // TODO Adicionar verificação quando a informação for incluída no token
      this.form.tipoComentario = '2';

      this.$emit('salvar-novo', this.form);
      this.$refs['modal'].fecharModal();
    },
    confirmacaoSalvar: function () {
      if (this.edicao) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    definirTitulo(tipoJustificativa) {
      if (tipoJustificativa == 'excluir') {
        this.titulo = this.$t(
          'modulos.contrato.tabela.modal_add_revisao.excluir_revisao'
        );
        this.justificativa = this.$t(
          'modulos.contrato.tabela.modal_add_revisao.justificativa_excluir_revisao'
        );
      } else {
        this.titulo = this.$t(
          'modulos.contrato.tabela.modal_add_revisao.nova_revisao'
        );
        this.justificativa = this.$t(
          'modulos.contrato.tabela.modal_add_revisao.justificativa_nova_revisao'
        );
      }
    },
  },
};
</script>

<template>
  <modal-padrao
    ref="modal-reenviar-email"
    max-width="80%"
    :titulo="$t('modulos.orcamentos.reenviar_email')"
    titulo-cancelar="Fechar"
    titulo-ok="Reenviar"
    :ok-desabilitado="!valido"
    @ok="reenviarEmail"
  >
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.destinatario="{ item }">
        <input-text
          v-model="item.item.destinatario"
          style="width: 230px; text-align-last: center"
          class="mb-2"
        />
      </template>
      <template v-slot:item.corpo="{ item }">
        <div
          class="col-12 pointer" 
          @click="verCorpoEmail(item.item.corpo)"
        >
          <icone-padrao
            :icone="'$eye'"
            :tooltip="'Ver Corpo do Email'"
          />
        </div>
      </template>
    </tabela-padrao>
    <corpo-email-modal
      ref="modal-corpo-email"
    />
  </modal-padrao>
</template>
<script>
import helpers from '@common/utils/helpers';
import EmailService from '@common/services/cadastros/EmailService';
import CorpoEmailModal from '@components/fragments/Modals/CorpoEmailModal';
export default {
  components: {
    CorpoEmailModal
  },
  props: {
    tipoEmail: { type: String, default: '' },
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'icones',
            text: '',
          },
          {
            value: 'destinatario',
            text: this.$t('modulos.orcamentos.tabela.destinatario'),
            sortable: true,
            width: '150px',
          },
          {
            value: 'assunto',
            text: this.$t('modulos.orcamentos.tabela.assunto'),
            sortable: true,
          },
          {
            value: 'corpo',
            text: this.$t('modulos.orcamentos.tabela.corpo_email'),
            sortable: true,
          },
          {
            value: 'dataCriacao',
            text: this.$t('modulos.orcamentos.tabela.data_envio'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'dataAlteracao',
            text: this.$t('modulos.orcamentos.tabela.data_atualizacao'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'enviado',
            text: this.$t('modulos.orcamentos.tabela.enviado'),
            sortable: true,
            formatter: (v) => {
              if (v) return 'Sim';
              else return 'Não';
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  computed: {
    valido: function () {
      if( this.tabela.selecionados.length) return true;
      return null
    },
  },
  methods: {
    abrirModal: function (id) {
      this.$refs['modal-reenviar-email'].abrirModal();
      if (this.tipoEmail == 'orcamento') {
        this.buscarDadosEmailsOrcamentos(id);
      }
      if (this.tipoEmail == 'contrato') {
        this.buscarDadosEmailsContratos(id);
      }
    },
    buscarDadosEmailsContratos: function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      let params = {
        contratoId: id,
      };
      EmailService.listarEmailsContratos(params)
        .then((res) => {
          this.tabela.dados = res.data.items;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarDadosEmailsOrcamentos: function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      let params = {
        orcamentoId: id,
      };
      EmailService.listarEmailsOrcamentos(params)
        .then((res) => {
          this.tabela.dados = res.data.items;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    verCorpoEmail(corpo) {
      this.$refs['modal-corpo-email'].abrirModal(corpo);
    },
    reenviarEmail() {
      this.tabela.selecionados.map(item => {
        let params = {
          remetente: item.destinatario
        }
        EmailService.reenviarEmail(item.emailId, params)
          .then(() => {
            this.toastSucesso(this.$t(`modulos.orcamentos.email_reenviado_sucesso`));
          })
          .catch((err) => {
            this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
          })
          .finally(() => {
            this.$store.dispatch("Layout/terminarCarregamento");
            this.$refs['modal-reenviar-email'].fecharModal();
          });
      })

    },
  },
};
</script>
<style>
.accordion-padrao {
  color: #0d47a1;
}
</style>

export const enumContratoIcons = [
  {
    value: 'EmNegociacao',
    message: 'Em Negociação',
    icon: 'fileDocumentEditOutline',
  },
  {
    value: 'AguardandoAprovacao',
    message: 'Aguardando Aprovação',
    icon: 'fileClockOutline',
  },
  {
    value: 'PreAssinado',
    message: 'Pré-Assinado',
    icon: 'fileEyeOutline',
  },
  {
    value: 'Assinado',
    message: 'Assinado',
    icon: 'fileCheckOutline',
  },
  {
    value: 'Reprovado',
    message: 'Reprovado',
    icon: 'fileDocumentRemoveOutline',
  },
  {
    value: 'Cancelado',
    message: 'Cancelado',
    icon: 'fileCancelOutline',
  },
  {
    value: 'Encerrado',
    message: 'Encerrado',
    icon: 'fileLockOutline',
  },
  {
    value: 'AguardandoEnvioParaCliente',
    message: 'Aguardando Envio para o Cliente',
    icon: 'accountClockOutline',
  },
  {
    value: 'AguardandoValidacaoKeL',
    message: 'Aguardando Validação K&L',
    icon: 'fileClockOutline',
  },
];

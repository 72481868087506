<template>
  <div>
    <modal-padrao
      ref="modal-lista-revisao"
      :titulo="$t('modulos.contrato.tabela.modal_list_revisao.titulo')"
      max-width="80%"
      :mostra-botao-salvar="false"
      :titulo-cancelar="$t('geral.botoes.fechar')"
      @ok="fecharModal"
    >
      <div class="d-flex align-center justify-space-between">
        <span />
        <div class="d-flex justify-start">
          <!-- <botao-padrao
            v-if="podeExcluir"
            class="my-2 mr-2"
            @click="abrirContratoVisualizar"
          >
            {{ $t('geral.botoes.visualizar_contrato') }}
          </botao-padrao> -->
          <botao-padrao
            v-if="buscarPermissao('Contrato', 'Criar Revisões')"
            class="my-2"
            @click="adicionarRevisaoOrcamento"
          >
            {{ $t('geral.botoes.nova_revisao') }}
          </botao-padrao>
        </div>
      </div>
      <tabela-padrao
        v-model="tabela.selecionados"
        :dados="tabela.dados"
        class="mt-2"
        :colunas="tabela.colunas"
        :por-pagina="tabela.porPagina"
        :quantidade-paginas="tabela.quantidadePaginas"
        selecionar-apenas-um
        :mostrar-checkbox="false"
        @paginar="listarRegistros"
      >
        <template v-slot:item.acoes="{ item }">
          <div class="d-flex">
            <div>
              <dropdown-padrao
                text
                color="secondary"
                style="width: 100% !important"
              >
                <template #botao>
                  <v-icon> $dotsVertical </v-icon>
                </template>

                <v-list-item-group style="overflow-y: auto">
                  <v-list-item
                    class="d-flex min-height-drop-item"
                    @click="abrirContratoVisualizar(item.item)"
                  >
                    {{ $t('geral.botoes.visualizar') }}
                  </v-list-item>
                </v-list-item-group>
              </dropdown-padrao>
            </div>
          </div>
        </template>
      </tabela-padrao>
    </modal-padrao>
  </div>
</template>
<script>
import ContratoService from '@common/services/cadastros/ContratoService';
import helpers from '@common/utils/helpers';
export default {
  data() {
    return {
      item: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
            width: 50,
          },
          {
            value: 'codigo',
            text: this.$t(
              'modulos.contrato.tabela.modal_list_revisao.tabela.codigo'
            ),
            width: 100,
            sortable: true,
          },
          {
            value: 'dataInicio',
            text: this.$t(
              'modulos.contrato.tabela.modal_list_revisao.tabela.data_emissao'
            ),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'participante.nome',
            text: this.$t(
              'modulos.contrato.tabela.modal_list_revisao.tabela.usuario'
            ),
            sortable: true,
          },
          {
            value: 'valorMensal',
            text: this.$t(
              'modulos.contrato.tabela.modal_list_revisao.tabela.valor_mensal'
            ),
            sortable: true,
            formatter: (v) => helpers.formatarMoeda(v),
          },
          {
            value: 'valorFinal',
            text: this.$t(
              'modulos.contrato.tabela.modal_list_revisao.tabela.valor_final'
            ),
            sortable: true,
            formatter: (v) => helpers.formatarMoeda(v),
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      id: null,
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  mounted() {},
  methods: {
    abrirModal: function (item) {
      this.id = item.id;
      this.item = item;
      this.tabela.selecionados = [];

      this.listarRegistros();
      this.$refs['modal-lista-revisao'].abrirModal();
    },
    fecharModal: function () {
      this.$refs['modal-lista-revisao'].fecharModal();
    },
    listarRegistros: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        id: this.id,
        page: paginaAtual,
        amountPerPage: porPagina,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      ContratoService.buscarRevisoes(this.id, parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abrirContratoVisualizar: function (item) {
      this.$router.push({
        name: 'contrato-editar',
        params: { id: item.id },
        query: { visualizacao: true}
      });
    },
    adicionarRevisaoOrcamento: function () {
      this.$emit('salvar-novo', this.id);
      this.fecharModal();
    },
    excluirRegistros: function () {
      this.$emit('salvar-novo', this.tabela.selecionados[0], 'excluir');
      this.fecharModal();
    },
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
  },
};
</script>

<template lang="">
  <modal-padrao
    ref="modal-ajustar-valor"
    max-width="60%"
    :titulo-ok="$t('geral.botoes.salvar')"
    :titulo="$t('modulos.contrato.titulos.reajuste_contrato')"
    @ok="salvar"
    @modal-fechado="fecharModal"
  >
    <v-form
      v-if="modalAberto"
      ref="form"
      class="row"
    >
      <input-money
        v-model="form.ajuste"
        class="col-12 col-md-4"
        :label="$t('modulos.contrato.formulario.ajuste')"
        obrigatorio
        :options="optionsInputmoney"
        :regras-personalizadas="regraValorMaximo()"
        @input="simularAjusteContrato"
      />
      <input-money
        :value="valorMensal"
        disabled
        class="col-12 col-md-4"
        :label="$t('modulos.contrato.formulario.valor_mensal')"
      />
      <input-money
        :value="form.valorItens"
        disabled
        class="col-12 col-md-4"
        :label="$t('modulos.contrato.formulario.valor_itens')"
        type="number"
      />
      <input-select
        v-model="form.tipoAcrescimo"
        class="col-12 col-md-4"
        obrigatorio
        :label="$t('modulos.contrato.formulario.tipo_acrescimo')"
        :options="tipo_ajuste"
      />
      <input-money
        v-model="form.acrescimo"
        class="col-12 col-md-4"
        :label="$t('modulos.contrato.formulario.acrescimo')"
        :options="optionsInputmoney"
        obrigatorio
        :incremento="0.01"
        :regras-personalizadas="regraValorMaximo(form.tipoAcrescimo)"
      />
      <input-select
        v-model="form.tipoDesconto"
        obrigatorio
        class="col-12 col-md-4"
        :label="$t('modulos.contrato.formulario.tipo_desconto')"
        :options="tipo_ajuste"
      />
      <input-money
        v-model="form.desconto"
        class="col-12 col-md-4"
        :label="$t('modulos.contrato.formulario.desconto')"
        :options="optionsInputmoney"
        obrigatorio
        :incremento="0.01"
        :regras-personalizadas="regraValorMaximo(form.tipoDesconto)"
      />
      <input-money
        :value="valorFinal"
        disabled
        class="col-12 col-md-4"
        :label="$t('modulos.contrato.formulario.valor_final_rs')"
      />
    </v-form>
  </modal-padrao>
</template>
<script>
import ContratoService from '@common/services/cadastros/ContratoService';
import { ContratoModel } from '@common/models/cadastros/ContratoModel';

export default {
  data() {
    return {
      primeiraVez: true,
      modalAberto: false,
      contrato: null,
      optionsInputmoney: {
        locale: 'pt-BR',
        prefix: null,
        suffix: '',
        precision: 4,
      },
      tipo_ajuste: [
        {
          text: `${this.$t('modulos.contrato.formulario.porcentagem')} (%)`,
          value: 1,
        },
        {
          text: `${this.$t('modulos.contrato.formulario.valor')} (R$)`,
          value: 2,
        },
      ],
      calibracao_manutencao: [
        {
          text: this.$t('modulos.contrato.formulario.calibracao'),
          value: 1,
        },
        {
          text: this.$t('modulos.contrato.formulario.manutencao'),
          value: 2,
        },
      ],
      quantidadeParcelas: 1,
      descontoBloqueado: true,
      acrescimoBloqueado: true,
      form: {},
    };
  },
  computed: {
    valorFinal() {
      return (
        this.form.valorFinal -
        (this.form.descontoCalculado || 0) +
        (this.form.acrescimoCalculado || 0)
      );
    },
    valorMensal() {
      return this.valorFinal / this.quantidadeParcelas;
    },
  },
  watch: {
    'form.desconto'() {
      // Se for Porcentagem, multiplica o valor total * %; Se for Valor só retorna.
      if (this.primeiraVez) return;
      this.aplicarDesconto();

    },
    'form.acrescimo'() {
      // Se for Porcentagem, multiplica o valor total * %; Se for Valor só retorna.
      if (this.primeiraVez) return;
      this.aplicarAcrescimo();
    },
  },
  methods: {
    aplicarAcrescimo(){
      this.form.tipoAcrescimo == 1
        ? (this.form.acrescimoCalculado =
            this.form.valorFinal * (this.form.acrescimo / 100))
        : (this.form.acrescimoCalculado = parseFloat(this.form.acrescimo));
    },
    aplicarDesconto(){
      this.form.tipoDesconto == 1
        ? (this.form.descontoCalculado =
            this.form.valorFinal * (this.form.desconto / 100))
        : (this.form.descontoCalculado = parseFloat(this.form.desconto));
    },
    regraValorMaximo: function (tipoValor = null) {
      const regraValor = (v) => {
        if (tipoValor == 2) return true;
        if (parseFloat(v.replace('.', '')) > 100)
          return this.$t('modulos.contrato.validacoes.valor_entre_zero_cem');
        return true;
      };
      return [regraValor];
    },
    fecharModal() {
      this.modalAberto = false;
      this.primeiraVez = true;
    },
    abrirModal: async function (id) {
      this.modalAberto = true;
      await this.$nextTick();
      //Inicia o Modal
      this.iniciarForm();
      this.buscarContrato(id);
      this.$refs['modal-ajustar-valor'].abrirModal();
    },
    iniciarForm() {
      //Cria/Reseta o Formulário
      this.form = {
        ajuste: '0.00',
        valorMensal: 0,
        valorItens: 0,
        acrescimo: '0.00',
        acrescimoCalculado: 0,
        desconto: '0.00',
        descontoCalculado: 0,
        valorFinal: 0,
        tipoAcrescimo: 1,
        tipoDesconto: 1,
      };
    },
    buscarContrato: function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ContratoService.buscar(id)
        .then((res) => {
          this.contrato = new ContratoModel(res.data);
          this.contrato.participante = res.data.participante?.nome;
          this.contrato.participanteId = res.data.participante?.id;
          this.contrato.participanteContatoId =
            res.data.participanteContato?.id;
          this.contrato.participanteContaId = localStorage.getItem(
            'participanteLogadoId'
          );
          this.contrato.valorFinal = res.data.valorFinal;
          this.form.valorFinal = res.data.valorFinal;
          this.form.valorItens = res.data.valorItens;
          this.form.tipoAcrescimo =
            res.data.tipoAcrescimo == 'Percentual' ? 1 : 2;
          this.form.tipoDesconto =
            res.data.tipoDesconto == 'Percentual' ? 1 : 2;
          this.form.desconto = res.data.valorDesconto;
          this.form.acrescimo = res.data.valorAcrescimo;

          this.quantidadeParcelas = this.contrato.numeroParcela;

          this.contrato.contratoServicoManutencao = [];
          if (res.data.contratoServicoManutencao.length) {
            res.data.contratoServicoManutencao.map((item) => {
              this.contrato.contratoServicoManutencao.push({
                tipoInstrumentoId: item.tipoInstrumento.id,
                modeloInstrumentoId: item.modeloInstrumento.id,
                servicoId: item.servico.id,
                servicoDescricao: item.servicoDescricao,
                valor: item.valor,
                tipoInstrumentoNome: item.tipoInstrumento.nome,
                modeloInstrumentoNome: item.modeloInstrumento.nome,
                servicoValor: item.valor,

                servicoCodigo: item.codigoServico,
              });
            });
          }

          this.contrato.contratoPeca = [];
          if (res.data.contratoPeca.length) {
            res.data.contratoPeca.map((item) => {
              this.contrato.contratoPeca.push({
                id: item.id,
                percentualDesconto: item.percentualDesconto,
                tipoInstrumentoId: item.tipoInstrumento.id,
                modeloInstrumentoId: item.modeloInstrumento.id,
                itemId: item.item.id,
                itemDescricao: item.itemDescricao,
                tipoInstrumentoNome: item.tipoInstrumento.nome,
                modeloInstrumentoNome: item.modeloInstrumento.nome,
                itemValor: item.valor,
                itemValorDesconto: item.valorComDesconto,

                itemCodigo: item.item.codigo,
              });
            });
          }

          this.contrato.contratoInstrumento.map((item) => {
            item.codigo = item.instrumento?.codigo;
            item.instrumentoId = item.instrumento?.id;
            item.tipoCalibracaoId = item.tipoCalibracao?.id;
            item.tipoCalibracaoNome = item.tipoCalibracao?.nome;
            item.localExecucaoId = item.localExecucao?.id;
            item.localExecucaoNome = item.localExecucao?.nome;
          });

          this.contrato.contratoInstrumento.forEach((item) => {
            if (item.contratoInstrumentoServicoCalibracao?.length) {
              item.contratoInstrumentoServicoCalibracao.map((itemDetalhe) => {
                itemDetalhe.servicoId = itemDetalhe.servico?.id;
                itemDetalhe.servicoCodigo = itemDetalhe.servico?.codigo;
                itemDetalhe.valorServico = itemDetalhe.valorUnitarioServico;
              });
            }
          });
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.contrato.erros.id_invalido'));
          this.$refs['modal-ajustar-valor'].fecharModal();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.primeiraVez = false;
          this.simularAjusteContrato();
          this.$nextTick().then(() => {
            this.aplicarAcrescimo();
            this.aplicarDesconto();
          })
        });
    },
    calcularAjusteItemValorUnitario(unitario) {
      return Math.floor(unitario * (this.form.ajuste / 100) + unitario); //Valor Item * {form.ajuste}%
    },
    simularAjusteContrato(event, salvar = false) {
      if (!this.$refs['form'].validate()) return; //Se o form.ajuste estiver abaixo de 100, ok faça!

      this.form.valorItens = 0; //Caso o usuario "simule" novamente o ajuste, zerar os valor total dos itens para calcular novamente.
      // this.contrato.contratoServicoManutencao?.forEach(
      //   (manutencao) => (this.form.valorItens += manutencao.valor ?? 0)
      // ); //Soma o valor de manutenção ao valor total dos itens
      // this.contrato.contratoPeca?.forEach(
      //   (peca) => (this.form.valorItens += peca.itemValor ?? 0)
      // ); //Soma o valor de peças ao valor total dos itens

      this.contrato.contratoInstrumento?.forEach((item) => {
        //Loop em contratoItens
        item.contratoInstrumentoServicoCalibracao?.forEach((calibracao) => {
          //Loop nos detalhes dos itens
          let valorAjustado = this.calcularAjusteItemValorUnitario(
            calibracao.valorTotalCalibracao
          );

          // let valorTotal = valorUnitario * calibracao.quantidadeTotalCalibracao; //Valor ajustado * quantidade
          this.form.valorItens += valorAjustado; //Atribui o valor ao form para aparecer no input
          if (salvar) {
            //Se for pra salvar o ajuste (PUT), alterar o objeto original dos itens
            calibracao.ValorTotalCalibracao = valorAjustado;
            // calibracao.ValorUnitarioServico = valorUnitario;
          }
        });

        this.form.valorFinal = this.form.valorItens; //Valor final é o mesmo de itens
        this.form.valorMensal =
          this.form.valorFinal / this.contrato.numeroParcela; //Valor mensal parcelado

        if (salvar) {
          //Se for pra salvar o ajuste (PUT), alterar o objeto original do contrato
          this.contrato.valorMensal = this.valorMensal.toFixed(2);
          this.contrato.valorFinal = this.valorFinal.toFixed(2);
          this.contrato.valorItens = this.form.valorItens.toFixed(2);
          this.contrato.tipoAcrescimo = this.form.tipoAcrescimo;
          this.contrato.tipoDesconto = this.form.tipoDesconto;
          this.contrato.valorDesconto = parseFloat(this.form.desconto) || 0;
          this.contrato.valorAcrescimo = parseFloat(this.form.acrescimo) || 0;
        }
      });
    },
    salvar() {
      this.confirmarSalvar().then(() => {
        this.simularAjusteContrato(null, true);
        this.salvarAjusteValor(this.contrato);
      });
    },

    salvarAjusteValor: function (contrato) {
      if (!this.$refs['form'].validate()) return; //Se o form.ajuste estiver abaixo de 100, ok faça!
      this.$store.dispatch('Layout/iniciarCarregamento');

      ContratoService.salvar(contrato)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.contrato.ajuste_valor_sucesso`));
          this.$emit('atualizar-listagem-contrato');
          this.$refs['modal-ajustar-valor'].fecharModal();
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
<style>
.checkboxValorContratoModal {
  gap: 20px;
}
</style>

<template>
  <modal-padrao
    ref="modal-data-encerramento"
    max-width="50%"
    titulo="Data de Encerramento"
    titulo-cancelar="Cancelar"
    titulo-ok="Confirmar"
    :ok-desabilitado="!valido"
    @ok="enviarDataAssinatura"
  >
    <v-form
      ref="form"
      class="row mt-2"
    >
      <span class="col-12">
        {{ $t("modulos.contrato.formulario.informe_data_encerrar_contrato") }}
      </span>
      <input-date
        :key="keyAtualiza"
        v-model="form.dataEncerramento"
        class="col-12 col-md-6"
        :label="$t('modulos.contrato.formulario.data')"
        obrigatorio
        em-filtro
      />
      <input-textarea
        v-model="form.justificativa"
        class="col-12"
        :label="$t('modulos.contrato.formulario.justificativa')"
        :placeholder="$t('modulos.contrato.formulario.justificativa')"
        obrigatorio
      />
    </v-form>
  </modal-padrao>
</template>
<script>
export default {
  props: {
    idAssinatura: { type: String, default: "" },
    requisitosCliente: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      form: {},
      dataAssinatura: null,
      keyAtualiza: 1,
      valido: null,
    };
  },
  watch: {
    form: {
      handler() {
        setTimeout(() => {
          if (this.$refs.form) this.valido = this.$refs.form.validate();
        }, 300);
      },
      deep: true,
    },
  },
  methods: {
    iniciarForm: function () {
      this.form = {
        id: this.idAssinatura,
        dataAssinatura: null,
      };
    },
    abrirModal: function () {
      this.iniciarForm();
      this.keyAtualiza++;
      this.$refs["modal-data-encerramento"].abrirModal();
    },
    enviarDataAssinatura() {
      this.$refs["modal-data-encerramento"].fecharModal();
      this.form.id = this.idAssinatura;
      this.$emit("alterar-status-contrato", this.form);
    },
  },
};
</script>
<style>
.accordion-padrao {
  color: #0d47a1;
}
</style>

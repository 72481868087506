<template>
  <modal-padrao
    ref="modal-corpo-email"
    max-width="80%"
    :titulo="'Corpo do Email'"
    titulo-cancelar="Fechar"
    :mostra-botao-salvar="false"
  >
    <span v-html="corpo" />
  </modal-padrao>
</template>
<script>
export default {
  props: {
    form: {},
  },
  data() {
    return {
      corpo: null,
    };
  },
  methods: {
    abrirModal: function (corpo) {
      this.corpo = corpo
      this.$refs['modal-corpo-email'].abrirModal();
    },
  },
};
</script>
<style>
.accordion-padrao {
  color: #0d47a1;
}
</style>
